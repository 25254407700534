import React, { useState } from "react";
import Button from "../common/button";
import Spacer from "../common/spacer";
import { useParams } from "react-router-dom";
import ManageLock from "./manage-lock";
import style from "./manage-lock-button.module.css";
import { CreateLockProvider } from "../../providers/createLock/createLockProvider";

export function ManageLockButton({ lockId, isOwner, unlockDate, amount }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { address, id } = useParams();

  function closeModal() {
    setIsDialogOpen(false);
  }

  function openModal() {
    setIsDialogOpen(true);
  }
  return (
    <>
      <Spacer my={0.5} />
      <Button
        disabled={!isOwner}
        className={style.manage_lock_button}
        onClick={openModal}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16.829"
          height="23.714"
          viewBox="0 0 16.829 23.714"
        >
          <path
            d="M13.994.4H3.285A3.06,3.06,0,0,0,.225,3.46v7.65a3.06,3.06,0,0,0,3.06,3.06H13.994a3.06,3.06,0,0,0,3.06-3.06V3.46A3.06,3.06,0,0,0,13.994.4Z"
            transform="translate(-0.225 9.544)"
          />
          <path
            d="M.325,5.43a5.355,5.355,0,1,1,10.709,0v4.59H9.5V5.43a3.825,3.825,0,0,0-7.65,0v4.59H.325Z"
            transform="translate(2.735 -0.075)"
            fillRule="evenodd"
          />
        </svg>
        <p> Manage Lock</p>
      </Button>
      <Spacer my={1} />
      <CreateLockProvider>
        <ManageLock
          isOpen={isDialogOpen}
          onCloseModal={closeModal}
          tokenAddress={address}
          lockId={lockId}
          lockIndex={id}
          amount={amount}
          unlockDate={unlockDate}
        />
      </CreateLockProvider>
    </>
  );
}
