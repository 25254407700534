import React from "react";
import search from "../../assets/images/search.svg";
import loading from "../../assets/images/loading.svg";
import "./search-group.css";
function SearchGroup({
  handleSearch,
  handleChange,
  value,
  placeholder,
  isLoading,
  example,
  isSearch,
  className,
  ...props
}) {
  return (
    <div className={`search-group ${className}`}>
      <div className="w-full relative">
        <input
          className="w-full"
          type="text"
          value={value || ''}
          placeholder={placeholder}
          onChange={handleChange}
          {...props}
        />
        {isLoading ? (
          <img className="search_loading" src={loading} alt="The Search Icon" />
        ) : (
          isSearch && <img src={search} alt="The Search Icon" />
        )}
      </div>
      {example && <p className="eg">e.g {example}</p>}
    </div>
  );
}

export default SearchGroup;
