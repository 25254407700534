import React, { useCallback, useEffect, useState } from "react";
import style from "./lock-table.module.css";
import { NoLocksGeneral } from "./mylocks";
import { useListOfLocks } from "../../providers/listOfLocks/listOfLocksProvider";
import { Link } from "react-router-dom";
import shortedAddress from "../../utils/shortenAddress";
import GenLoading from "../common/gen-loading";
import { chainToUse } from "../../utils/utils";
import { useDingerDapp } from "../../providers/DingerDappProvider/MoralisDappProvider";

function LockTable() {
  const [isLoading, setIsLoading] = useState(false);
  const {chainId} = useDingerDapp();
  const [locks, setLocks] = useState([]);
  const [locksAvailable, setLocksAvailable] = useState(false);
  const [errors, setErrors] = useState([]);
  // const [tableLimit, setTableLimit] = useState(20);
  const { allLocks, errors: err, isLoading: fetching } = useListOfLocks();

  const runGeLocks = useCallback(async () => {
    setIsLoading(fetching);
    setErrors(err);
    const hasLocks = allLocks?.length > 0;
    setLocksAvailable(hasLocks);
    if (hasLocks) {
      setLocks(allLocks);
    }
  }, [allLocks, fetching, err]);

  useEffect(() => {
    runGeLocks();
  }, [runGeLocks]);

  return (
    <div>
      <div className={`${style.lTable_container} w-full  table__data`}>
        {locksAvailable ? (
          <div>
            <div className="w-full">
              <table className={`${style.lTable}`}>
                <thead className={`${style.lTableHead}`}>
                  <tr className="table__tr">
                    <th>Type</th>
                    <th>Symbol</th>
                    <th>Address</th>
                    <th className={`${style.linkCol}`}>Details</th>
                  </tr>
                </thead>
                <tbody className={`${style.lBody}`}>
                  {locks?.map((lock, i) => (
                    <tr className={`${style.tRow} table__tr`} key={i}>
                      <td className={`${style.lockDate} table__td`}>
                        <div>{lock?._is_lp}</div>
                      </td>

                      <td className={style.symbol}>
                        <div>
                          {lock?._symbol}
                          {lock?._is_lp === "Liquidity" && (
                            <span>
                              {lock?._token_0_symbol}/{lock?._token_1_symbol}
                            </span>
                          )}
                        </div>
                      </td>
                      <td className={style.token}>
                        <div>
                          <p>{shortedAddress(lock?._address, 6, 8)}</p>
                        </div>
                      </td>
                      <td
                        className={`${style.lockStatus} ${style.lockStatus_manage}`}
                      >
                        <Link
                          to={`/dlock/${chainToUse(chainId)}/locks/${
                            lock?._address
                          }`}
                        >
                          <div className={style.lockStatus_locked}>
                            <p>
                              <span>View</span>
                            </p>
                          </div>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* For small screens */}
              {/* <div className="t-mobile">
         {locks?.map((lock,index)=>(
            <div key={index} className="row">
            <h4 className="column-head">Type</h4>
            <p  className="column-data">{lock?._is_lp}</p>
            <h4 className="column-head">Symbol</h4>
            <p  className="column-data">
            {lock?._symbol}
                          {lock?._is_lp === "Liquidity" && (
                            <span>
                              {lock?._token_0_symbol}/{lock?._token_1_symbol}
                            </span>
                          )}
              </p>
            <h4 className="column-head">Address</h4>
            <p  className="column-data">{shortedAddress(lock?._address, 5, 4)}</p>
            <h4 className="column-head">Details</h4>
            <div  className="column-data">
            <Link to={`/dlock/${chainToUse(chainId)}/locks/${lock?._address}`}>
                          <div className={style.lockStatus_locked}>
                            <p>
                              <span>View</span>
                            </p>
                          </div>
                        </Link>
            </div>
          </div>
         ))}
        </div> */}
            </div>
          </div>
        ) : (
          <div className={`${style.lTable_no_locs} ${style.lTable_status}`}>
            {!isLoading ? (
              errors?.length ? (
                <div>
                  <h3>Opps! An Error occurred..</h3>
                  {errors.map((err, i) => (
                    <p key={i}>{JSON.stringify(err)}</p>
                  ))}
                </div>
              ) : (
                <NoLocksGeneral />
              )
            ) : (
              <GenLoading text={"Fetching latest lock data from chain..."} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default LockTable;
