import ERC20ABI from "../contracts/ERC20ABI.json";
import PAIRABI from "../contracts/UNIV2PAIR.json";
import lockerABI from "../contracts/DLock.json";
import empty from "../assets/images/empty-token.webp";
import { getPercentage } from "./utils";

const LOCKERABI = lockerABI.abi;

export async function getLockType(Moralis, chainId, address) {
  const opt = { chain: chainId, pair_address: address };
  let lockTp;
  try {
    if (await Moralis.Web3API.defi.getPairReserves(opt)) {
      lockTp = "Liquidity";
    }
  } catch (e) {
    lockTp = "Token";
  }

  return lockTp;
}

export async function getLocksAddressAndId(
  Moralis,
  chainId,
  lockerContract,
  address,
  id
) {
  const opt = {
    chain: chainId,
    address: lockerContract,
    function_name: "getLocksByTokenAddressAndId",
    abi: LOCKERABI,
    params: { _lockToken: address, _id: id },
  };

  return [await Moralis.Web3API.native.runContractFunction(opt)];
}

export async function getLocks(Moralis, chainId, lockerContract, address) {
  const opt = {
    chain: chainId,
    address: lockerContract,
    function_name: "getLocksByTokenAddress",
    abi: LOCKERABI,
    params: { _lockToken: address },
  };

  return await Moralis.Web3API.native.runContractFunction(opt);
}

export async function getMetaData(Moralis, chainId, address) {
  const opt = {
    chain: chainId,
    addresses: address,
  };

  return await Moralis.Web3API.token.getTokenMetadata(opt);
}

export async function getTokenPrice(Moralis, address) {
  const opt = {
    address: address,
  };

  try {
    return await Moralis.Web3API.token.getTokenPrice(opt);
  } catch (e) {
    return 0;
  }
}

export async function getPairTokens(Moralis, chainId, address) {
  const opt = {
    chain: chainId,
    address: address,
    abi: PAIRABI,
  };

  return {
    token0: await Moralis.Web3API.native.runContractFunction({
      function_name: "token0",
      ...opt,
    }),
    token1: await Moralis.Web3API.native.runContractFunction({
      function_name: "token1",
      ...opt,
    }),
  };
}

export async function getTotalSupply(Moralis, chainId, token, address) {
  const opt = {
    function_name: "totalSupply",
    address: address,
    abi: ERC20ABI,
    chain: chainId,
  };

  return parseFloat(
    Moralis.Units.FromWei(
      await Moralis.Web3API.native.runContractFunction(opt),
      token?.decimals
    ).toFixed(2)
  );
}

export async function getTokenDetails(Moralis, chainId, address) {
  const token = (await getMetaData(Moralis, chainId, address))[0];

  return {
    ...token,
    image: empty,
    decimals: token.decimals,
    symbol: token.symbol,
  };
}

export async function structuredLockData(
  Moralis,
  chainId,
  locks,
  token,
  address,
  isLp
) {
  const values = {
    lockedValue: 0,
    supplyValue: 0,
  };

  const tokensMeta = {
    token0Symbol: "",
    token1Symbol: "",
    token0Name: "",
    token1Name: "",
    token0Icon: "",
    token1Icon: "",
    lockType: "",
  };

  const totalSupply = await getTotalSupply(Moralis, chainId, token, address);
  const structuredLocks = locks?.map((lock) => ({
    address: address,
    lockDate: Number(lock[0]) * 1000,
    originalAmount: lock[1],
    amount: parseFloat(
      Moralis.Units.FromWei(lock[1], token?.decimals).toFixed(2)
    ),
    unlockDate: Number(lock[3]) * 1000,
    lockID: Number(lock[4]),
    owner: lock[5],
    percentage: getPercentage(
      parseFloat(Moralis.Units.FromWei(lock[1], token?.decimals).toFixed(2)),
      totalSupply
    ),
  }));

  const totalLockedAmount = structuredLocks.reduce(
    (acc, curr) => acc + curr.amount,
    0
  );
  const percentageOfTotal = getPercentage(totalLockedAmount, totalSupply);

  const nextUnlockDate = structuredLocks.reduce((acc, curr) => {
    if (curr.unlockDate > acc) {
      return curr.unlockDate;
    }
    return acc;
  }, 0);

  const byUnlockDate = structuredLocks.sort((a, b) => {
    if (a.unlockDate > b.unlockDate) return 1;
    if (a.unlockDate < b.unlockDate) return -1;
    return 0;
  });

  if (isLp) {
    let { token0: token00, token1: token01 } = await getPairTokens(
      Moralis,
      chainId,
      address
    );

    const pairMeta = await getMetaData(Moralis, chainId, [token01, token00]);

    tokensMeta.token0Symbol = pairMeta[0]?.symbol;
    tokensMeta.token1Symbol = pairMeta[1]?.symbol;
    tokensMeta.token0Name = pairMeta[0]?.name;
    tokensMeta.token1Name = pairMeta[1]?.name;
    tokensMeta.token0Icon = pairMeta[0]?.logo ? pairMeta[0]?.logo : empty;
    tokensMeta.token1Icon = pairMeta[1]?.logo ? pairMeta[1]?.logo : empty;
    tokensMeta.lockType = "Liquidity";

    const price = await getTokenPrice(Moralis, token00);
    const opt = { chain: chainId, pair_address: address };
    const result = (await Moralis.Web3API.defi.getPairReserves(opt));
    // console.log("resv1", result?.reserve0);
    // console.log("price", price);
    const supplyValue =
      parseFloat(Moralis.Units.FromWei(result?.reserve0, pairMeta[0]?.decimals).toFixed(2)) *
      price?.usdPrice *
      2;
    // console.log(
    //   "supplyValue breakdown",
    //   parseFloat(Moralis.Units.FromWei(result?.reserve0, pairMeta[0]?.decimals).toFixed(2)),
    //   price?.usdPrice
    // );
    // console.log("supplyValue", supplyValue);
    const lockedValue = (totalLockedAmount / totalSupply) * supplyValue;
    // console.log("lockedValue", lockedValue);

    values.lockedValue = lockedValue;
    values.supplyValue = supplyValue;
  } else {
    const price = await getTokenPrice(Moralis, address);
    tokensMeta.token1Symbol = token?.symbol;
    tokensMeta.token1Name = token?.name;
    tokensMeta.token1Icon = token?.logo ? token?.logo : empty;
    tokensMeta.lockType = "Token";

    values.lockedValue = totalLockedAmount * price?.usdPrice;
    values.supplyValue = totalSupply * price?.usdPrice;
  }

  return {
    lock_type: tokensMeta.lockType,
    total_token_supply: totalSupply,
    total_locked_tokens: totalLockedAmount,
    percentage_locked: percentageOfTotal,
    unlock_date: nextUnlockDate,
    locks: byUnlockDate,
    locked_value: values.lockedValue,
    total_value: values.supplyValue,
    token_1_symbol: tokensMeta.token1Symbol,
    token_1_name: tokensMeta.token1Name,
    token_1_icon: tokensMeta.token1Icon,
    token_0_symbol: tokensMeta.token0Symbol,
    token_0_name: tokensMeta.token0Name,
    token_0_icon: tokensMeta.token0Icon,
    pair_symbol: token?.symbol,
    token_decimals: token?.decimals,
  };
}
