import lockerABI from "../contracts/DLock.json";
// import ERC20ABI from "../contracts/ERC20ABI.json";
import { getPairTokens } from "./lockDetails";
import { isLp } from "./userLocks";

const LOCKERABI = lockerABI.abi;

export async function getAllLockAddresses(Moralis, chainId, lockerContract) {
  const opt = {
    chain: chainId,
    address: lockerContract,
    function_name: "getAllLockAddresses",
    abi: LOCKERABI,
  };

  try {
    return await Moralis.Web3API.native.runContractFunction(opt);
  } catch (err) {
    return [];
  }
}

export async function getAllLocks(Moralis, chainId, addresses) {
  let locks = [];
  if (addresses.length > 0) {
    const opt = {
      chain: chainId,
      addresses: addresses,
    };

    try {
      const tokenMeta = await Moralis.Web3API.token.getTokenMetadata(opt);
      locks = await Promise.all(
        tokenMeta.map(async (token) => {
          const symbols = { token_0_symbol: "", token_1_symbol: "" };
          const isLP = await isLp(Moralis, chainId, token?.address);
          if (isLP) {
            const { token0, token1 } = await getPairTokens(
              Moralis,
              chainId,
              token?.address
            );
            const pairMeta = await Moralis.Web3API.token.getTokenMetadata({
              ...opt,
              addresses: [token0, token1],
            });

            symbols.token_0_symbol = pairMeta[0]?.symbol;
            symbols.token_1_symbol = pairMeta[1]?.symbol;
          }

          return {
            _is_lp: isLP ? "Liquidity" : "Token",
            _address: token?.address,
            _symbol: token?.symbol,
            _token_0_symbol: symbols?.token_0_symbol,
            _token_1_symbol: symbols?.token_1_symbol,
          };
        })
      );
      return locks;
    } catch (err) {
      // console.log(err);
    }
  }
}
