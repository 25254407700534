import React, { useCallback, useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import style from "./search-lock.module.css";
import lockerABI from "../../contracts/DLock.json";
import { Link } from "react-router-dom";
import loading from "../../assets/images/loading.svg";
import { chainToUse } from "../../utils/utils";
import { useDingerDapp } from "../../providers/DingerDappProvider/MoralisDappProvider";

function SearchLocks() {
  const { Moralis, web3 } = useMoralis();
  const { chainId, locker: lockerContract } = useDingerDapp();
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [enteredAddress, setEnteredAddress] = useState("");
  const [foundLock, setFoundLock] = useState(false);

  const rundHandleSearch = useCallback(async () => {
    setIsSearching(true);
    await handleSearch();
    setIsSearching(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    rundHandleSearch();
  }, [rundHandleSearch]);

  async function handleSearch() {
    const opt = {
      chain: chainId,
      address: lockerContract,
      function_name: "getTotalLocksForToken",
      abi: lockerABI.abi,
      params: { _lockToken: searchValue },
    };

    if (lockerContract) {
      try {
        const locks = await Moralis.Web3API.native.runContractFunction(opt);
        if (Number(locks) > 0) {
          setFoundLock(true);
        } else {
          setFoundLock(false);
        }
      } catch (error) {
        setFoundLock(false);
      }
    }
  }

  function handleInputChange(e) {
    const value = e.target.value;
    setEnteredAddress(value);
    const meetsLimit = value.length === 42;
    const isAddress = web3.utils.isAddress(value);

    if (meetsLimit && isAddress) {
      setSearchValue(value);
    } else {
      setSearchValue("");
    }
  }

  return (
    <div>
      <div className="w-full flex items-center relative">
        <input
          className={`${style.lockerSearch}`}
          onChange={handleInputChange}
          type="search"
          id="locker-search"
          name="locker"
          aria-label="Search for locked tokens"
          placeholder="Search by token address"
          value={enteredAddress}
        ></input>

        <label htmlFor="site-search" className={` ${style.lockerSearchLabel}`}>
          <svg
            className="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            width="23.457"
            height="23.463"
            viewBox="0 0 23.457 23.463"
          >
            <path
              d="M27.682,26.258l-6.524-6.585A9.3,9.3,0,1,0,19.747,21.1l6.481,6.542a1,1,0,0,0,1.417.037A1.011,1.011,0,0,0,27.682,26.258Zm-13.83-5.076a7.341,7.341,0,1,1,5.192-2.15A7.3,7.3,0,0,1,13.852,21.182Z"
              transform="translate(-4.5 -4.493)"
              fill="#FFFFFF4D"
            />
          </svg>
        </label>
      </div>
      {(isSearching || foundLock) && (
        <div className={style.lockerSearchResult}>
          {isSearching && <img src={loading} alt="loading" />}

          {foundLock && (
            <Link to={`/dlock/${chainToUse(chainId)}/locks/${searchValue}`}>
              <div className={style.lockStatus_locked}>
                <p>
                  <span>{searchValue}</span>
                </p>
              </div>
            </Link>
          )}
        </div>
      )}
    </div>
  );
}

export default SearchLocks;
