export const defaultLockDetailsState = {
  lock_type: "Token",
  token_0_symbol: null,
  token_1_symbol: null,
  token_0_name: null,
  token_1_name: null,
  token_0_icon: null,
  token_1_icon: null,
  pair_symbol: null,
  token_decimals: null,
  total_locked_tokens: null,
  total_token_supply: null,
  percentage_locked: null,
  locked_value: null,
  total_value: null,
  unlock_days: null,
  unlock_date: null,
  withdrawn: false,
  hasError: false,
  error: null,
  locks: [],
};
