import React from "react";
import { useParams } from "react-router-dom";

const LaunchDetails = () => {
  const { launch } = useParams();

  return (
    <div>
      <h1>Launch Details: {launch}</h1>
    </div>
  );
};

export default LaunchDetails;
