import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import style from "../common/wallet-manager.module.css";
import style2 from "./manage-lock.module.css";
import style3 from "./new-lock-modal.module.css";
import lockerABI from "../../contracts/DLock.json";
import { useAlert } from "react-alert";
import {
  chainToUse,
  numberOfDaysTimeStamp,
  numberOfDaysTimeStampForContract,
  numberOfDaysToDateString,
  onInputNumberChange,
  timestampToDuration,
} from "../../utils/utils";
import SearchGroup from "../common/search-group";
import Button from "../common/button";
import loading from "../../assets/images/loading.svg";
import { useCreateLock } from "../../providers/createLock/createLockProvider";
import { useDingerDapp } from "../../providers/DingerDappProvider/MoralisDappProvider";

function ManageLock({
  isOpen,
  onCloseModal,
  tokenAddress,
  lockId,
  lockIndex,
  amount,
  unlockDate,
}) {
  const alert = useAlert();
  const navigate = useNavigate();
  const { Moralis, web3 } = useMoralis();
  const { chainId, locker: lockerContract } = useDingerDapp();
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [isTranfering, setIsTranfering] = useState(false);
  const [isExtending, setIsExtending] = useState(false);
  const [state, setState] = useState("initial");
  const [lockFees, setLockFees] = useState(0);
  const [newOwner, setNewOwnerAddress] = useState("");
  const [enteredAddress, setEnteredAddress] = useState("");
  const { lockDuration, setLockDuration } = useCreateLock();

  useEffect(() => {
    (async () => {
      await getLockFees();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function widthdrawLock() {
    setIsWithdrawing(true);
    const opt = {
      chain: chainId,
      contractAddress: lockerContract,
      functionName: "withdraw",
      abi: lockerABI.abi,
      params: {
        _lockToken: tokenAddress,
        _index: lockIndex,
        _lock_id: lockId,
        _amount: amount,
      },
    };

    const locked = await Moralis.executeFunction({
      awaitReceipt: false,
      ...opt,
    });
    locked
      .on("receipt", (out) => {
        alert.success("Lock withdrawn successfully");
        setIsWithdrawing(false);
        navigate(`/dlock/${chainToUse(chainId)}`);
      })
      .on("error", (error) => {
        alert.error("Withdrawal failed!");
        setIsWithdrawing(false);
      });
  }

  async function getLockFees() {
    const opt = {
      chain: chainId,
      address: lockerContract,
      function_name: "gFees",
      abi: lockerABI.abi,
    };

    try {
      const fees = await Moralis.Web3API.native.runContractFunction(opt);
      setLockFees(fees);
    } catch (error) {
      setLockFees(0);
    }
  }

  async function transferOwnership() {
    setIsTranfering(true);
    const opt = {
      chain: chainId,
      contractAddress: lockerContract,
      functionName: "transferLockOwnership",
      abi: lockerABI.abi,
      params: {
        _lockToken: tokenAddress,
        _index: lockIndex,
        _lock_id: lockId,
        _new_owner: newOwner,
      },
    };

    const locked = await Moralis.executeFunction({
      awaitReceipt: false,
      ...opt,
    });
    locked
      .on("receipt", (out) => {
        alert.success("Lock ownership transferred successfully");
        console.log(out);
        setIsTranfering(false);
        navigate(`/dlock/${chainToUse(chainId)}/locks/${tokenAddress}`);
      })
      .on("error", (error) => {
        alert.error("Lock onwership Transfer failed!");
        setIsTranfering(false);
      });
  }

  async function extendLockDuration() {
    setIsExtending(true);
    const opt = {
      chain: chainId,
      contractAddress: lockerContract,
      functionName: "relock",
      abi: lockerABI.abi,
      params: {
        _lockToken: tokenAddress,
        _index: lockIndex,
        _lock_id: lockId,
        _unlock_date: numberOfDaysTimeStampForContract(lockDuration),
      },
      msgValue: lockFees,
    };

    const locked = await Moralis.executeFunction({
      awaitReceipt: false,
      ...opt,
    });
    locked
      .on("receipt", (out) => {
        alert.success("Lock duration extended");
        // console.log(out);
        setIsExtending(false);
        window.location.reload();
      })
      .on("error", (error) => {
        alert.error("Failed to extend duration!");
        setIsExtending(false);
      });
  }

  function canUnlock() {
    return unlockDate && Date.now() >= unlockDate;
  }

  function canExtend() {
    return unlockDate && numberOfDaysTimeStamp(lockDuration) > unlockDate;
  }
  function handleInputChange(e) {
    const value = e.target.value;
    setEnteredAddress(value);
    const meetsLimit = value.length === 42;
    const isAddress = web3.utils.isAddress(value);

    if (meetsLimit && isAddress) {
      setNewOwnerAddress(value);
    } else {
      setNewOwnerAddress("");
    }
  }

  const handleDayChange = (value) => {
    setLockDuration(value);
  };

  // states
  function initialState() {
    return (
      <div className={style.wallet_dialog_content}>
        <div className={style.wallet_dialog_header}>
          <Dialog.Title as="h3" className={style.wallet_dialog_title}>
            Connect your wallet
          </Dialog.Title>

          <span onClick={onCloseModal} className={style.wallet_dialog_close}>
            &times;
          </span>
        </div>

        <div className={style.wallet_dialog_providers}>
          <ul className={style2.wallet_dialog_functions}>
            <li>
              <button
                className={style2.wallet_unlock_button}
                disabled={!canUnlock()}
                onClick={() => {
                  if (canUnlock()) widthdrawLock();
                }}
              >
                <span className={style2.unlock_btn}>
                  Withdraw
                  {isWithdrawing && (
                    <img src={loading} alt="loading" width="30px" />
                  )}
                </span>
                <p className={style2.unlocks_in}>
                  <span>Unlocks in {timestampToDuration(unlockDate)}</span>
                  <span>{new Date(unlockDate).toLocaleString()}</span>
                </p>
              </button>
            </li>
            <li>
              <button onClick={() => setState("tranferLock")}>
                <span>Transfer Ownership</span>
              </button>
            </li>
            <li>
              <button onClick={() => setState("extend")}>
                <span>Extend Lock/Relock</span>
              </button>
            </li>
            {/* <li>
          <button onClick={() => null}>
            <span>View Transactions</span>
          </button>
        </li> */}
          </ul>
        </div>
      </div>
    );
  }

  function tranferLock() {
    return (
      <div className={style.wallet_dialog_content}>
        <div className={style.wallet_dialog_header}>
          <span
            onClick={() => setState("initial")}
            className={`${style.wallet_dialog_close} ${style2.back_button}`}
            style={{ alignSelf: "flex-start" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 23.747 9.971"
              style={{ transform: "rotateY(180deg)" }}
              width="28px"
              height="28px"
            >
              <path
                d="M14.3,11.443a.679.679,0,0,0-.005.956l3.157,3.163-20.813.005a.673.673,0,0,0-.67.675.673.673,0,0,0,.67.675l20.808-.005-3.157,3.163a.683.683,0,0,0,.005.956.672.672,0,0,0,.95-.005l4.279-4.31h0a.758.758,0,0,0,.14-.213.644.644,0,0,0,.052-.26.677.677,0,0,0-.192-.473l-4.279-4.31A.662.662,0,0,0,14.3,11.443Z"
                transform="translate(4.035 -11.252)"
                fill="#fff"
              />
            </svg>
          </span>
          <Dialog.Title
            as="h3"
            className={`${style.wallet_dialog_title} flex flex-col`}
          >
            <div className={`${style3.title}`}>
              <p className={style3.title_p}>Transfer Lock Ownership</p>
            </div>
            <span className={`${style3.sub} ${style3.title_p_sub}`}>
              Make sure the new address is right!
            </span>
          </Dialog.Title>

          <span
            onClick={onCloseModal}
            className={style.wallet_dialog_close}
            style={{ alignSelf: "flex-start" }}
          >
            &times;
          </span>
        </div>

        <div className={style.wallet_dialog_providers}>
          <SearchGroup
            value={enteredAddress}
            example={"0x0..."}
            handleChange={(e) => handleInputChange(e)}
          />
        </div>

        <div className={style3.continue_button}>
          <Button
            disabled={!newOwner}
            type={isTranfering ? "outlined" : "normal"}
            isLoading={isTranfering}
            className={style3.continue_button_button}
            handleClick={transferOwnership}
          >
            {newOwner && <p>Transfer</p>}
            {!newOwner && <p>Invalid Address</p>}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23.747"
              height="9.971"
              viewBox="0 0 23.747 9.971"
            >
              <path
                d="M14.3,11.443a.679.679,0,0,0-.005.956l3.157,3.163-20.813.005a.673.673,0,0,0-.67.675.673.673,0,0,0,.67.675l20.808-.005-3.157,3.163a.683.683,0,0,0,.005.956.672.672,0,0,0,.95-.005l4.279-4.31h0a.758.758,0,0,0,.14-.213.644.644,0,0,0,.052-.26.677.677,0,0,0-.192-.473l-4.279-4.31A.662.662,0,0,0,14.3,11.443Z"
                transform="translate(4.035 -11.252)"
              />
            </svg>
          </Button>
        </div>
      </div>
    );
  }

  function extendLock() {
    return (
      <div className={style.wallet_dialog_content}>
        <div className={style.wallet_dialog_header}>
          <span
            onClick={() => setState("initial")}
            className={`${style.wallet_dialog_close} ${style2.back_button}`}
            style={{ alignSelf: "flex-start" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 23.747 9.971"
              style={{ transform: "rotateY(180deg)" }}
              width="28px"
              height="28px"
            >
              <path
                d="M14.3,11.443a.679.679,0,0,0-.005.956l3.157,3.163-20.813.005a.673.673,0,0,0-.67.675.673.673,0,0,0,.67.675l20.808-.005-3.157,3.163a.683.683,0,0,0,.005.956.672.672,0,0,0,.95-.005l4.279-4.31h0a.758.758,0,0,0,.14-.213.644.644,0,0,0,.052-.26.677.677,0,0,0-.192-.473l-4.279-4.31A.662.662,0,0,0,14.3,11.443Z"
                transform="translate(4.035 -11.252)"
                fill="#fff"
              />
            </svg>
          </span>
          <Dialog.Title
            as="h3"
            className={`${style.wallet_dialog_title} flex flex-col`}
          >
            <div className={`${style3.title}`}>
              <p className={style3.title_p}>Extend Lock duration</p>

              <span className={`${style3.sub} ${style3.title_p_sub}`}>
                New unlock date must be after previous unlock date.
              </span>
            </div>
          </Dialog.Title>

          <span
            onClick={onCloseModal}
            className={style.wallet_dialog_close}
            style={{ alignSelf: "flex-start" }}
          >
            &times;
          </span>
        </div>

        <div className={style3.configure_lock_section}>
          <div className={style3.configure_lock_left}>
            <h3 className={style3.configure_lock_title}>Unlock Date</h3>
            <SearchGroup
              placeholder={1}
              value={lockDuration}
              handleChange={(e) => {
                onInputNumberChange(e, handleDayChange);
              }}
              className={style3.configure_lock_input}
            />
          </div>
          <div className={style3.configure_lock_right}>
            <h3 className={style3.configure_lock_balance}>
              {numberOfDaysToDateString(lockDuration)}
            </h3>
            <div className={style3.configure_lock_icons}>
              <p className={style3.configure_lock_symbol}>Days</p>
            </div>
          </div>
        </div>

        <div className={style3.continue_button}>
          <Button
            disabled={lockDuration <= 0 && !canExtend()}
            type={isExtending ? "outlined" : "normal"}
            isLoading={isExtending}
            className={style3.continue_button_button}
            handleClick={() => {
              if (canExtend) extendLockDuration();
            }}
          >
            {lockDuration > 0 && <p>Extend</p>}
            {lockDuration < 0.01 && <p>Invalid Duration</p>}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23.747"
              height="9.971"
              viewBox="0 0 23.747 9.971"
            >
              <path
                d="M14.3,11.443a.679.679,0,0,0-.005.956l3.157,3.163-20.813.005a.673.673,0,0,0-.67.675.673.673,0,0,0,.67.675l20.808-.005-3.157,3.163a.683.683,0,0,0,.005.956.672.672,0,0,0,.95-.005l4.279-4.31h0a.758.758,0,0,0,.14-.213.644.644,0,0,0,.052-.26.677.677,0,0,0-.192-.473l-4.279-4.31A.662.662,0,0,0,14.3,11.443Z"
                transform="translate(4.035 -11.252)"
              />
            </svg>
          </Button>
        </div>
      </div>
    );
  }
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className={style.wallet_dialog} onClose={onCloseModal}>
          <div className={style.wallet_dailog_containter}>
            <Dialog.Overlay className={style.wallet_dialog_overlay} />

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className={style.wallet_dialog_containter_spacer}
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {
                {
                  initial: initialState(),
                  tranferLock: tranferLock(),
                  extend: extendLock(),
                }[state]
              }
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default ManageLock;
