import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  BSCLogo,
  Cronos,
  ETHLogo,
  Invalid,
  PolygonLogo,
  FTMLogo,
  AvaxLogo,
} from "../common/Logos";
import { useChain, useMoralis } from "react-moralis";
import style from "./chains.module.css";
import { useDingerDapp } from "../../providers/DingerDappProvider/MoralisDappProvider";

const chains = [
  {
    key: "0x1",
    value: "Ethereum",
    icon: <ETHLogo />,
    disabled: false,
  },
  {
    key: "0x38",
    value: "BSC",
    icon: <BSCLogo />,
    disabled: false,
  },
  {
    key: "0x89",
    value: "Polygon",
    icon: <PolygonLogo />,
    disabled: false,
  },
  {
    key: "0xfa",
    value: "Fantom",
    icon: <FTMLogo />,
    disabled: false,
  },
  {
    key: "0xa86a",
    value: "Avalanche",
    icon: <AvaxLogo />,
    disabled: false,
  },
  {
    key: "0x19",
    value: "Cronos",
    icon: <Cronos />,
    disabled: false,
  },
  {
    key: "0x3",
    value: "Ropsten",
    icon: <ETHLogo />,
    disabled: false,
  },
];
const chainsRoutes = {
  "0x1": "erc",
  "0x38": "bsc",
  "0x89": "polygon",
  "0xfa": "ftm",
  "0xa86a": "avax",
  "0x19": "cronos",
  "0x3": "ropsten",
};

function Chains() {
  let { switchNetwork } = useChain();
  const { chainId, setChainId } = useDingerDapp();
  const { isAuthenticated } = useMoralis();

  const [selected, setSelected] = useState({});

  useEffect(() => {
    const newSelected = chains.find((item) => {
      return item.key === chainId ?? "0x1";
    });
    setSelected(newSelected);
  }, [chainId]);

  const handleMenuClick = (key, status) => {
    if (!status) {
      if (isAuthenticated) {
        switchNetwork(key);
      } else {
        setChainId(key);
      }
      window.location = `/dlock/${chainsRoutes[key]}`;
    }
  };

  return (
    <div className="text-right">
      <Menu as="div" className={style.menu_container}>
        <div>
          {/* {isAuthenticated ? ( */}
          <Menu.Button className={style.menu_button}>
            {selected?.icon || (isAuthenticated ? <Invalid /> : <ETHLogo />)}{" "}
            {selected?.value || (isAuthenticated ? "Invalid" : "Ethereum")}
          </Menu.Button>
          {/* ) : (
            <div className={`${style.menu_button} ${style.menu_notAuth}`}>
              {selected?.icon || (isAuthenticated ? <Invalid /> : <ETHLogo />)}{" "}
              {selected?.value ||
                (isAuthenticated ? "Unsupported" : "Ethereum")}
            </div>
          )} */}
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className={style.menu_items}>
            <div className="px-1 py-1 ">
              {chains.map(({ key, value, icon, disabled }) => {
                return (
                  <Menu.Item
                    key={key}
                    onClick={() => handleMenuClick(key, disabled)}
                  >
                    {({ active }) => (
                      <button
                        className={`${style.chain_item} ${
                          active ? "bg-violet-500 text-white" : "text-gray-900"
                        }`}
                        disabled={disabled}
                      >
                        {/* {active ? (
                          <EditActiveIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        ) : (
                          <EditInactiveIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        )} */}
                        {icon}
                        {value}
                      </button>
                    )}
                  </Menu.Item>
                );
              })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

export default Chains;
