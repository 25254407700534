import React from "react";
import loading from "../../assets/images/loading.svg";

const style = {
  loading: {
    paddingTop: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
  },
};

function GenLoading({ text }) {
  return (
    <div style={style.loading}>
      <img src={loading} alt="loading" width="60px" />
      <p>{text}</p>
    </div>
  );
}

export default GenLoading;
