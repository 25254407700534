import React from "react";

function TermsOfService() {

  return (
    <div>
      <h1>TOS</h1>
    </div>
  );
}

export default TermsOfService;
