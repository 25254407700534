import React, { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { farmToUse } from "../../utils/utils";
import DingerDappContext from "./context";

function DingerDappProvider({ children }) {
  const { web3, Moralis, user } = useMoralis();
  const [walletAddress, setWalletAddress] = useState();
  const [chainId, setChainId] = useState();
  const [locker, setLocker] = useState();

  // const [launchPadContract, setLaunchPadContract] = useState();
  useEffect(() => {
    Moralis.onChainChanged(function (chain) {
      setChainId(chain);
      setLocker(farmToUse(chain));
    });

    Moralis.onAccountsChanged(function (address) {
      setWalletAddress(address[0]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(() => {
  //   if (isAuthenticated) {
  //     setChainId(web3.givenProvider?.chainId);
  //   }
  // });

  useEffect(() => setLocker(farmToUse(chainId)), [chainId]);

  useEffect(
    () =>
      setWalletAddress(
        web3.givenProvider?.selectedAddress || user?.get("ethAddress")
      ),
    [web3, user]
  );

  return (
    <DingerDappContext.Provider
      value={{ walletAddress, chainId, setChainId, locker, setLocker }}
    >
      {children}
    </DingerDappContext.Provider>
  );
}

function useDingerDapp() {
  const context = React.useContext(DingerDappContext);
  if (context === undefined) {
    throw new Error("useDingerDapp must be used within a DingerDappProvider");
  }
  return context;
}

export { DingerDappProvider, useDingerDapp };
