import React, { useCallback, useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { getAllLockAddresses, getAllLocks } from "../../utils/allLocks";
import { getLockAddress } from "../../utils/userLocks";
import { useDingerDapp } from "../DingerDappProvider/MoralisDappProvider";
import ListOfLocks from "./context";

function ListOfLocksProvider({ children }) {
  const [userLocks, setUserLocks] = useState([]);
  const [allLocks, setAllLocks] = useState([]);
  const { isAuthenticated, Moralis } = useMoralis();
  const { chainId, walletAddress, locker: lockerContract } = useDingerDapp();
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //   const [fetchLimit, setFetchLimit] = useState(20);

  const runGetUserLocks = useCallback(async () => {
    if (isAuthenticated && walletAddress) {
      // get user lock addresses
      // from allLocks filter out user locks
      if (lockerContract) {
        try {
          const userLockAddresses = await getLockAddress(
            Moralis,
            chainId,
            lockerContract,
            walletAddress
          );

          if (allLocks.length > 0) {
            const userLocks = userLockAddresses.map((address) => {
              return allLocks.find(
                (lock) =>
                  lock?._address?.toLowerCase() === address?.toLowerCase()
              );
            });
            setUserLocks(userLocks);
          }
          setErrors([]);
        } catch ({ error }) {
          setErrors([...errors, error]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, chainId, walletAddress, allLocks]);

  const runGetAllLocks = useCallback(async () => {
    if (lockerContract) {
      try {
        setIsLoading(true);
        const lockAddresses = await getAllLockAddresses(
          Moralis,
          chainId,
          lockerContract
        );

        if (lockAddresses.length > 0) {
          const locks = await getAllLocks(Moralis, chainId, lockAddresses);
          setAllLocks(locks);
        }
        setIsLoading(false);
        setErrors([]);
      } catch ({ error }) {
        setErrors([...errors, error]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);

  useEffect(() => {
    runGetAllLocks();
  }, [runGetAllLocks]);

  useEffect(() => {
    runGetUserLocks();
  }, [runGetUserLocks]);

  return (
    <ListOfLocks.Provider
      value={{
        userLocks,
        allLocks,
        errors,
        isLoading,
      }}
    >
      {children}
    </ListOfLocks.Provider>
  );
}

function useListOfLocks() {
  const context = React.useContext(ListOfLocks);
  if (context === undefined) {
    throw new Error("useListOfLocks must be used within a ListOfLocks Context");
  }
  return context;
}

export { ListOfLocksProvider, useListOfLocks };
