import React, { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { useDingerDapp } from "../DingerDappProvider/MoralisDappProvider";
import CreatLockContext from "./context";

function CreateLockProvider({ children }) {
  const {  Moralis } = useMoralis();
  const [selectedLockChain, setSelectedLockChain] = useState();
  const [tokenDetails, setTokenDetails] = useState({});
  const [lockType, setLockType] = useState();
  const [lockAddress, setLockAddress] = useState("");
  const [lockAmount, setLockAmount] = useState(0);
  const [lockDuration, setLockDuration] = useState(1);
  const [lockComplete, setLockComplete] = useState(false);
  const { chainId, setChainId } = useDingerDapp();

  const resetState = () => {
    setLockType(undefined);
    setLockAddress("");
    setLockAmount(0);
    setLockDuration(1);
    setLockComplete(false);
    setSelectedLockChain(undefined);
    setTokenDetails({});
  };

  useEffect(() => {
    Moralis.onChainChanged(function (chain) {
      setChainId(chain);
    });

    // reset states when lock is complete
    if (lockComplete) {
      resetState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CreatLockContext.Provider
      value={{
        selectedLockChain,
        setSelectedLockChain,
        lockType,
        setLockType,
        lockAddress,
        setLockAddress,
        lockAmount,
        setLockAmount,
        lockDuration,
        setLockDuration,
        lockComplete,
        setLockComplete,
        chainId,
        tokenDetails,
        setTokenDetails,
        resetState,
      }}
    >
      {children}
    </CreatLockContext.Provider>
  );
}

function useCreateLock() {
  const context = React.useContext(CreatLockContext);
  if (context === undefined) {
    throw new Error("useCreateLock must be used within a CreatLockContext");
  }
  return context;
}

export { CreateLockProvider, useCreateLock };
