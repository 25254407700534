import React from "react";

function Catapult() {

  return (
    <div>
      <h1>Catapult</h1>
    </div>
  );
}

export default Catapult;