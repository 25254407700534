import React, { useCallback, useEffect, useState } from "react";
import style from "./mylocks.module.css";
import noLockAssets from "../../assets/images/user-locks.png";
import Button from "../common/button";
import Spacer from "../common/spacer";
import CreateLock from "./new-lock-modal";
import { CreateLockProvider } from "../../providers/createLock/createLockProvider";
import { useMoralis } from "react-moralis";
import WalletManager from "../common/wallet-manager";
import UserLocksTable from "./user-locks";
import { useListOfLocks } from "../../providers/listOfLocks/listOfLocksProvider";
import { useDingerDapp } from "../../providers/DingerDappProvider/MoralisDappProvider";

export function NoLocksGeneral() {
  return (
    <div className={style.no_locks}>
      <img src={noLockAssets} alt="no locks" />
      <h1 className={style.no_locks_header}>There are no locks</h1>
      <p className={style.no_locks_summary}>Be the first to create a lock!</p>
      <MyLocksButton />
    </div>
  );
}

function NoLocks() {
  return (
    <div className={style.no_locks}>
      <img src={noLockAssets} alt="no locks" />
      <h1 className={style.no_locks_header}>You have no locks</h1>
      <p className={style.no_locks_summary}>
        You have not locked up any coins yet. Create some new locks!
      </p>
      <MyLocksButton />
    </div>
  );
}

function HasLocks({ locks }) {
  return (
    <div className={style.has_locks}>
      <UserLocksTable locks={locks} />
      <MyLocksButton />
    </div>
  );
}

function MyLocksButton() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { isAuthenticated, Moralis } = useMoralis();
  const { chainId } = useDingerDapp();

  function closeModal() {
    setIsDialogOpen(false);
  }

  function openModal() {
    if (isAuthenticated) {
      (async () => {
        await Moralis.switchNetwork(chainId);
      })();
    }
    setIsDialogOpen(true);
  }

  return (
    <>
      <CreateLockProvider>
        <Spacer my={0.5} />
        <Button className={style.no_locks_button} handleClick={openModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16.829"
            height="23.714"
            viewBox="0 0 16.829 23.714"
          >
            <path
              d="M13.994.4H3.285A3.06,3.06,0,0,0,.225,3.46v7.65a3.06,3.06,0,0,0,3.06,3.06H13.994a3.06,3.06,0,0,0,3.06-3.06V3.46A3.06,3.06,0,0,0,13.994.4Z"
              transform="translate(-0.225 9.544)"
            />
            <path
              d="M.325,5.43a5.355,5.355,0,1,1,10.709,0v4.59H9.5V5.43a3.825,3.825,0,0,0-7.65,0v4.59H.325Z"
              transform="translate(2.735 -0.075)"
              fillRule="evenodd"
            />
          </svg>
          <p> Create New Lock</p>
        </Button>
        <Spacer my={1} />
        {isAuthenticated ? (
          <CreateLock isOpen={isDialogOpen} onCloseModal={closeModal} />
        ) : (
          <WalletManager isOpen={isDialogOpen} onCloseModal={closeModal} />
        )}
      </CreateLockProvider>
    </>
  );
}

function MyLocks() {
  const { isAuthenticated } = useMoralis();
  const [isLoading, setIsLoading] = useState(false);
  const [userLocks, setUserLocks] = useState([]);
  const [userWithLocks, setUserHasLocks] = useState(false);
  const { userLocks: locks } = useListOfLocks();

  const runGetUserLocks = useCallback(async () => {
    setIsLoading(true);
    const hasLocks = locks?.length > 0;
    setUserHasLocks(hasLocks);
    if (hasLocks) {
      setUserLocks(locks);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locks, isAuthenticated]);

  useEffect(() => {
    runGetUserLocks();
  }, [runGetUserLocks]);

  return (
    <div className={style.user_locks}>
      <div className={style.user_locks_header}>
        <h2>My Locks</h2>
      </div>
      <div className={style.user_locks_content}>
        {
          {
            noLocks: <NoLocks />,
            hasLocks: <HasLocks locks={userLocks} />,
          }[userWithLocks && !isLoading ? "hasLocks" : "noLocks"]
        }
      </div>
    </div>
  );
}

export default MyLocks;
