import dextools from "../assets/images/dextools-logo.svg";
import etherscan from "../assets/images/etherscan-logo.svg";
import bscscan from "../assets/images/bscscan-logo.svg";
import polygonscan from "../assets/images/polygonscan-logo.svg";
import ftmscan from "../assets/images/ftmscan-logo.svg";
import snowtrace from "../assets/images/snowtrace-logo.svg";
import cronosscan from "../assets/images/cronosscan-logo.svg";

export function isNonZeroNumber(_input) {
  return _input !== undefined && _input !== "" && parseFloat(_input) !== 0.0;
}

export function onInputNumberChange(e, f) {
  const re = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
  if (e.target.value === "" || re.test(e.target.value)) {
    f(e.target.value);
  }
}

function treatAsUTC(date) {
  var result = new Date(Number(date));
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result.getTime();
}

export function timestampToNumberOfDay(timestamp) {
  const millisecondePerDay = 24 * 60 * 60 * 1000;
  let now = treatAsUTC(Date.now());
  let future = treatAsUTC(Number(timestamp));

  const dys = Math.ceil((future - now) / millisecondePerDay);
  return dys > 0 ? dys : 0;
}

export function timestampToDuration(timestamp) {
  const days = timestampToNumberOfDay(timestamp);
  if (days > 365) {
    return `${(days / 365).toFixed(2)} ${
      (days / 365).toFixed(2) > 1 ? "Years" : "Year"
    }`;
  } else {
    return `${days} ${days > 1 ? "Days" : "Day"}`;
  }
}

// export function timestampToNumberOfDayExtra(timestamp) {
//   const days = timestampToNumberOfDay(timestamp);

//   // if (days > 100) {
//   //   return
//   // }
// }

export function numberOfDaysTimeStamp(days) {
  const millisecondePerDay = 24 * 60 * 60 * 1000;
  const daysTimestamp = Number(days) * millisecondePerDay;
  const finalDate = treatAsUTC(Date.now()) + treatAsUTC(daysTimestamp);
  return new Date(finalDate).getTime();
}

export function numberOfDaysTimeStampForContract(days) {
  return parseInt(numberOfDaysTimeStamp(days) / 1000);
}

export function numberOfDaysToDateString(days) {
  const timestamp = numberOfDaysTimeStamp(days);
  const date = new Date(timestamp);
  const month = date.toLocaleString("default", {
    month: "long",
  });
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

export function getPercentage(value, total) {
  return ((Number(value) / Number(total)) * 100).toFixed(2);
}

export function findObjectByKey(obj, key) {
  return obj?.find((item) => item?.key?.toLowerCase() === key?.toLowerCase());
}

export const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  notation: "compact",
  maximumFractionDigits: 2,
});

export const genFormatter = new Intl.NumberFormat("en-US", {
  notation: "compact",
  maximumFractionDigits: 2,
});

export const getUsdFormat = (value) => {
  if (Number.parseFloat(value) > 0.04) {
    return usdFormatter.format(value);
  } else {
    return `$ ${Number.parseFloat(value).toExponential(2)}`;
  }
};

export const getUSDValue = (ethInLp, ethPrice) => {
  const supply = ethInLp * 2;
  return supply * ethPrice;
};

export const chainToUse = (chainId) => {
  const chains = {
    "0x1": "erc",
    "0x38": "bsc",
    "0x89": "polygon",
    "0xfa": "ftm",
    "0xa86a": "avax",
    "0x19": "cronos",
    "0x3": "ropsten",
  };

  return chains[chainId];
};

export const chainIdToUse = (chain) => {
  const chains = {
    erc: "0x1",
    bsc: "0x38",
    polygon: "0x89",
    ftm: "0xfa",
    avax: "0xa86a",
    cronos: "0x19",
    ropsten: "0x3",
  };

  return chains[chain];
};

export const farmToUse = (chainId) => {
  const farmPools = {
    // eth
    "0x1": process.env.REACT_APP_DLOCK_ETH_CONTRACT,
    // bsc
    "0x38": process.env.REACT_APP_DLOCK_BSC_CONTRACT,
    // polygon
    "0x89": process.env.REACT_APP_DLOCK_POLYGON_CONTRACT,
    // fantom
    "0xfa": process.env.REACT_APP_DLOCK_FANTOM_CONTRACT,
    // avax
    "0xa86a": process.env.REACT_APP_DLOCK_AVAX_CONTRACT,
    // cronos
    "0x19": process.env.REACT_APP_DLOCK_CRONOS_CONTRACT,
    // ropsten
    "0x3": process.env.REACT_APP_DLOCK_ROPSTEN_CONTRACT,
  };
  // console.log("selected chain to use", chainId);
  // console.log("selected farm to use", farmPools[chainId]);
  return farmPools[chainId];
};

export const linksToUse = (chainId, address) => {
  return {
    "0x1": [
      {
        name: "Dextools",
        icon: dextools,
        link: `https://www.dextools.io/app/ether/pair-explorer/${address}`,
      },
      {
        name: "Etherscan",
        icon: etherscan,
        link: `https://etherscan.io/address/${address}`,
      },
    ],
    "0x38": [
      {
        name: "Dextools",
        icon: dextools,
        link: `https://www.dextools.io/app/bsc/pair-explorer/${address}`,
      },
      {
        name: "BscScan",
        icon: bscscan,
        link: `https://bscscan.com/address/${address}`,
      },
    ],
    "0x89": [
      {
        name: "Dextools",
        icon: dextools,
        link: `https://www.dextools.io/app/polygon/pair-explorer/${address}`,
      },
      {
        name: "PolygonScan",
        icon: polygonscan,
        link: `https://polygonscan.com/address/${address}`,
      },
    ],
    "0xfa": [
      {
        name: "Dextools",
        icon: dextools,
        link: `https://www.dextools.io/app/fantom/pair-explorer/${address}`,
      },
      {
        name: "FTMScan",
        icon: ftmscan,
        link: `https://ftmscan.com/address/${address}`,
      },
    ],
    "0xa86a": [
      {
        name: "Dextools",
        icon: dextools,
        link: `https://www.dextools.io/app/avalanche/pair-explorer/${address}`,
      },
      {
        name: "Snowtrace",
        icon: snowtrace,
        link: `https://snowtrace.io/address/${address}`,
      },
    ],
    "0x19": [
      {
        name: "Dextools",
        icon: dextools,
        link: `https://www.dextools.io/app/cronos/pair-explorer/${address}`,
      },
      {
        name: "CronosScan",
        icon: cronosscan,
        link: `https://cronoscan.com/address/${address}`,
      },
    ],
    "0x3": [
      {
        name: "Etherscan",
        icon: etherscan,
        link: `https://ropsten.etherscan.io/address/${address}`,
      },
    ],
  }[chainId ?? "0x1"];
};
