import lockerABI from "../contracts/DLock.json";

const LOCKERABI = lockerABI.abi;

export async function userHasLocks(
  Moralis,
  chainId,
  lockerContract,
  userAddres
) {
  const opt = {
    chain: chainId,
    address: lockerContract,
    function_name: "getUserTotalLockedTokens",
    abi: LOCKERABI,
    params: { _user: userAddres },
  };

  try {
    const userLocks = await Moralis.Web3API.native.runContractFunction(opt);
    return userLocks > 0;
  } catch (err) {
    return false;
  }
}

export async function getLockAddress(
  Moralis,
  chainId,
  lockerContract,
  userAddres
) {
  const opt = {
    chain: chainId,
    address: lockerContract,
    function_name: "getUserFull",
    abi: LOCKERABI,
    params: { _user: userAddres },
  };

  return await Moralis.Web3API.native.runContractFunction(opt);
}

export async function getUserLocks(
  Moralis,
  chainId,
  lockerContract,
  userAddres
) {
  try {
    let lockSummary = [];
    const userLockAddresses = await getLockAddress(
      Moralis,
      chainId,
      lockerContract,
      userAddres
    );

    if (userLockAddresses.length > 0) {
      const opt = {
        chain: chainId,
        addresses: userLockAddresses,
      };
      const tokenInfo = await Moralis.Web3API.token.getTokenMetadata(opt);

      lockSummary = await Promise.all(
        tokenInfo.map(async (token) => {
          let isLP = await isLp(Moralis, chainId, token?.address);

          return {
            is_lp: isLP ? "Liquidity" : "Token",
            address: token?.address,
            symbol: token?.symbol,
          };
        })
      );
    }
    return lockSummary;
  } catch (err) {
    return [];
  }
}

export async function isLp(Moralis, chainId, address) {
  try {
    const opt = { chain: chainId, pair_address: address };
    await Moralis.Web3API.defi.getPairReserves(opt);
    return true;
  } catch (err) {
    return false;
  }
}
