import React from "react";
import style from "./top-stats.module.css";
import lock from "../../assets/images/3d-lock-illustration.png";
// import tvl from "../../assets/images/tvl.svg";
// import tpartcnt from "../../assets/images/tparticipants.svg";
// import projects from "../../assets/images/projects.svg";
// import Spacer from "../common/spacer";

// function StatsCard({ icon, subtext, maintext }) {
//   return (
//     <div className={style.stats_card}>
//       <div className={style.stats_card_icon}>
//         <img src={icon} alt="dlock" />
//       </div>
//       <div className={style.stats_card_text}>
//         <h1 className={style.stats_card_maintext}>{maintext}</h1>
//         <h1 className={style.stats_card_subtext}>{subtext}</h1>
//       </div>
//     </div>
//   );
// }

function TopStats() {
  return (
    <div className={style.top_stats}>
      <div className={style.top_stats_head}>
        <img src={lock} alt="dlock" />
        <h1 className={style.top_stats_title}>D-Lock</h1>
      </div>
      {/* <Spacer my={1} /> */}
      {/* <div className={style.top_stats_body}>
        <StatsCard
          icon={tvl}
          subtext={"Total Locked Value"}
          maintext={"$200000000"}
        />
        <StatsCard
          icon={projects}
          subtext={"Number of Projects Locked"}
          maintext={"$200000000"}
        />
        <StatsCard
          icon={tpartcnt}
          subtext={"Total Projects"}
          maintext={"$200000000"}
        />
      </div> */}
    </div>
  );
}

export default TopStats;
