import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "../common/wallet-manager.module.css";
import style2 from "./new-lock-modal.module.css";
import weth from "../../assets/images/weth.svg";
import eth from "../../assets/images/eth.svg";
import bsc from "../../assets/images/bsc.svg";
import cronos from "../../assets/images/cronos.svg";
import polygon from "../../assets/images/polygon.svg";
import fantom from "../../assets/images/fantom.svg";
import avax from "../../assets/images/avax.svg";
import empty from "../../assets/images/empty-token.webp";
import lpt from "../../assets/images/liquidity-tokens.svg";
import pjt from "../../assets/images/project-tokens.svg";
import lockWhite from "../../assets/images/lock-fill-white.svg";
import lockYellow from "../../assets/images/lock-fill-yellow.svg";
import lockdate from "../../assets/images/lock-yellow.svg";
import baseline from "../../assets/images/baseline.svg";
import Button from "../common/button";
import { useCreateLock } from "../../providers/createLock/createLockProvider";
import SearchGroup from "../common/search-group";
import { useMoralis, useERC20Balances } from "react-moralis";
import ERC20ABI from "../../contracts/ERC20ABI.json";
import lockerABI from "../../contracts/DLock.json";
import { useAlert } from "react-alert";

import {
  chainToUse,
  findObjectByKey,
  genFormatter,
  numberOfDaysTimeStampForContract,
  numberOfDaysToDateString,
  onInputNumberChange,
} from "../../utils/utils";
import { useDingerDapp } from "../../providers/DingerDappProvider/MoralisDappProvider";

const states = {
  0: "chain",
  1: "type",
  2: "address",
  3: "config",
  4: "summary",
};

const supportedNetworks = [
  {
    key: "0x1",
    displayName: "Ethereum",
    icon: eth,
  },
  {
    key: "0x38",
    displayName: "BSC",
    icon: bsc,
  },
  {
    key: "0x89",
    displayName: "Polygon",
    icon: polygon,
  },
  {
    key: "0xfa",
    displayName: "Fantom",
    icon: fantom,
  },
  {
    key: "0xa86a",
    displayName: "Avalanche",
    icon: avax,
  },
  {
    key: "0x19",
    displayName: "Cronos",
    icon: cronos,
  },
  {
    key: "0x3",
    displayName: "Ropsten",
    icon: weth,
  },
];

const lockTypes = [
  {
    key: "1",
    displayName: "Liquidity Tokens",
    icon: lpt,
    summary: "LP Tokens generated from Liquidity provider",
  },
  {
    key: "2",
    displayName: "Project Tokens",
    icon: pjt,
    summary: "Standard ERC20-compatible Project Tokens",
  },
];

function CreateLock({ isOpen, onCloseModal }) {
  const navigate = useNavigate();
  const alert = useAlert();
  const { web3, Moralis, isAuthenticated } = useMoralis();
  const { selectedLockChain, setSelectedLockChain, chainId } = useCreateLock();
  const { lockType, setLockType, resetState } = useCreateLock();
  const { tokenDetails, setTokenDetails } = useCreateLock();
  const { lockAddress, setLockAddress } = useCreateLock();
  const { lockAmount, setLockAmount } = useCreateLock();
  const { lockDuration, setLockDuration } = useCreateLock();
  const { setLockComplete } = useCreateLock();
  const [state, setState] = useState(0);
  const [isValidAddress, setIsValidAddress] = useState(true);
  const [isValidAsset, setIsValidAsset] = useState();
  const [isLoadingToken, setIsLoadingToken] = useState(false);
  const [tokenAddress, setTokenAddress] = useState();
  const [balance, setBalance] = useState(0);
  const [balanceFull, setBalanceFull] = useState(0);
  const [allowance, setAllowance] = useState(0);
  const [approved, setApproved] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isLocking, setIsLocking] = useState(false);
  const [lockFees, setLockFees] = useState(0);
  const { walletAddress, locker: lockerContract } = useDingerDapp();
  const { data: tokens } = useERC20Balances();

  useEffect(() => {
    if (!chainId) return null;
    setSelectedLockChain(chainId);
    return () => {
      resetState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        const token = tokens?.find(
          ({ token_address }) =>
            token_address?.toLowerCase() === lockAddress?.toLowerCase()
        );

        const balance = parseFloat(
          Moralis.Units.FromWei(token?.balance || 0, token?.decimals)
        );

        setBalanceFull(balance);
        setBalance(balance.toFixed(2));
      }
    })();
    return () => {
      setBalanceFull(0);
      setBalance(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lockAddress]);

  useEffect(() => {
    (async () => {
      if (tokenAddress) {
        if (isValidAsset) {
          const options = { chain: chainId, addresses: tokenAddress };
          try {
            const token = await Moralis.Web3API.token.getTokenMetadata(options);
            setTokenDetails(token[0]);
          } catch {
            setTokenDetails(null);
          }
        }
        await getLockFees();
      }
    })();
    return () => {
      setTokenDetails(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidAsset, lockAddress, tokenAddress]);

  useEffect(() => {
    (async () => {
      if (tokenAddress) {
        const opt = {
          chain: chainId,
          owner_address: walletAddress,
          spender_address: lockerContract,
          address: lockAddress,
        };

        try {
          const { allowance } = await Moralis.Web3API.token.getTokenAllowance(
            opt
          );
          setAllowance(
            parseFloat(
              Moralis.Units.FromWei(
                allowance || 0,
                tokenDetails?.decimals
              ).toFixed(2)
            )
          );
        } catch {
          setAllowance(0);
        }
      }
    })();
    return () => {
      setAllowance(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lockAmount]);

  useEffect(() => {
    if (isAuthenticated) {
      if (allowance <= 0 || allowance < lockAmount) {
        setApproved(false);
      } else {
        setApproved(true);
      }
      return () => {
        setApproved(false);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowance]);

  function handleOnCloseModal() {
    onCloseModal();
    setTimeout(() => {
      setState(0);
      setTokenAddress(undefined);
      resetState();
    }, 500);
  }

  async function handleLockChainSelect(key) {
    try {
      await Moralis.switchNetwork(key);
      setSelectedLockChain(key);
    } catch (err) {
      setSelectedLockChain(chainId);
    }
  }

  const handleLockAmountChange = (amount) => {
    setLockAmount(amount);
  };

  const handleDayChange = (value) => {
    setLockDuration(value);
  };

  function handleInputChange(e) {
    const value = e.target.value;
    setTokenAddress(value);
    const meetsLimit = value.length === 42;
    const isAddress = web3.utils.isAddress(value);

    if (meetsLimit && isAddress) {
      setLockAddress(value);
      getTokenInfo(value);
      setIsValidAddress(true);
    }

    if (!isAddress) {
      setIsValidAddress(false);
    }
  }

  async function approveSpending() {
    setIsApproving(true);
    const amount = Moralis.Units.Token(lockAmount, tokenDetails?.decimals);
    const options = {
      chain: selectedLockChain,
      contractAddress: lockAddress,
      functionName: "approve",
      abi: ERC20ABI,
      params: { _spender: lockerContract, _value: amount },
    };

    try {
      const approved = await Moralis.executeFunction(options);
      const approvedValue = approved?.events?.Approval?.returnValues?.value;
      setAllowance(
        parseFloat(
          Moralis.Units.FromWei(approvedValue, tokenDetails?.decimals).toFixed(
            2
          )
        )
      );
      alert.success("Approval successful");
      setIsApproving(false);
    } catch ({ error }) {
      alert.error("Failed to approve. Please try again");
      setIsApproving(false);
    }
  }

  async function lockTheTokens() {
    const amount = Moralis.Units.Token(lockAmount, tokenDetails?.decimals);
    setIsLocking(true);
    const isLP = lockType === "1" ? 1 : 2;
    const lockOpt = {
      chain: selectedLockChain,
      contractAddress: lockerContract,
      functionName: "lockTokens",
      abi: lockerABI.abi,
      params: {
        _lockToken: lockAddress,
        _amount: amount,
        _unlock_date: numberOfDaysTimeStampForContract(lockDuration),
        _is_lp_tokens: isLP,
        _lock_owner: walletAddress,
      },
      msgValue: lockFees,
    };

    const locked = await Moralis.executeFunction({
      awaitReceipt: false,
      ...lockOpt,
    });
    locked
      .on(
        "receipt",
        ({
          events: {
            onDeposit: {
              returnValues: { Token },
            },
          },
        }) => {
          setIsLocking(false);
          setLockComplete(true);
          alert.success("Lock Complete!");
          navigate(`/dlock/${chainToUse(chainId)}/locks/${Token}`);
        }
      )
      .on("error", ({ error }) => {
        alert.error("Failed to lock. Please try again");
        setIsLocking(false);
      });
  }

  async function getLockFees() {
    if (isAuthenticated) {
      const opt = {
        chain: chainId,
        address: lockerContract,
        function_name: "gFees",
        abi: lockerABI.abi,
      };

      try {
        const fees = await Moralis.Web3API.native.runContractFunction(opt);
        setLockFees(fees);
      } catch (error) {
        setLockFees(0);
      }
    }
  }

  async function getTokenInfo(address) {
    setIsLoadingToken(true);
    const pairOpt = { chain: chainId, pair_address: address };

    if (lockType === "1") {
      try {
        await Moralis.Web3API.defi.getPairReserves(pairOpt);
        setIsValidAsset(true);
      } catch (error) {
        setIsValidAsset(false);
        setIsLoadingToken(true);
      }
    }

    if (lockType === "2") {
      try {
        const options = { chain: chainId, addresses: address };
        const token = await Moralis.Web3API.token.getTokenMetadata(options);
        try {
          if (await Moralis.Web3API.defi.getPairReserves(pairOpt)) {
            setLockType("1");
          }
        } catch {
          setLockType("2");
        }

        if (token[0].decimals) {
          setIsValidAsset(true);
        } else {
          setIsValidAsset(false);
        }
      } catch (error) {
        setIsValidAsset(false);
        setIsLoadingToken(true);
      }
    }

    setIsLoadingToken(false);
  }

  // states
  function selectChain() {
    return (
      <div className={style.wallet_dialog_content}>
        <div className={style.wallet_dialog_header}>
          <Dialog.Title as="h3" className={style.wallet_dialog_title}>
            <p className={style2.title}>
              <img src={lockWhite} alt="lock" />
              Create new lock
            </p>
            <span className={style2.sub}>
              Lock your Liqiudity or tokens with D-Lock
            </span>
          </Dialog.Title>

          <span
            onClick={handleOnCloseModal}
            className={style.wallet_dialog_close}
            style={{ alignSelf: "flex-start" }}
          >
            &times;
          </span>
        </div>

        <div className={style.wallet_dialog_providers}>
          <ul className={style2.wallet_dialog_network_list}>
            {supportedNetworks.map(({ displayName, icon, key }) => (
              <li key={key}>
                <button onClick={() => handleLockChainSelect(key)}>
                  <img src={icon} alt={displayName} />
                  <span className={style2.wallet_dialog_display_name}>
                    {displayName}
                  </span>
                  <span
                    className={`${style2.wallet_dialog_chainStatus} ${
                      key === selectedLockChain
                        ? style2.wallet_dialog_activeChain
                        : ""
                    }`}
                  ></span>
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div className={style2.continue_button}>
          <Button
            className={style2.continue_button_button}
            handleClick={() => setState(nextState(state))}
          >
            <p> Continue</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23.747"
              height="9.971"
              viewBox="0 0 23.747 9.971"
            >
              <path
                d="M14.3,11.443a.679.679,0,0,0-.005.956l3.157,3.163-20.813.005a.673.673,0,0,0-.67.675.673.673,0,0,0,.67.675l20.808-.005-3.157,3.163a.683.683,0,0,0,.005.956.672.672,0,0,0,.95-.005l4.279-4.31h0a.758.758,0,0,0,.14-.213.644.644,0,0,0,.052-.26.677.677,0,0,0-.192-.473l-4.279-4.31A.662.662,0,0,0,14.3,11.443Z"
                transform="translate(4.035 -11.252)"
              />
            </svg>
          </Button>
        </div>
      </div>
    );
  }

  function selectLockType() {
    return (
      <div className={style.wallet_dialog_content}>
        <div className={style.wallet_dialog_header}>
          <span
            onClick={() => setState(prevState(state))}
            className={`${style.wallet_dialog_close} ${style2.back_button}`}
            style={{ alignSelf: "flex-start" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 23.747 9.971"
              style={{ transform: "rotateY(180deg)" }}
              width="28px"
              height="28px"
            >
              <path
                d="M14.3,11.443a.679.679,0,0,0-.005.956l3.157,3.163-20.813.005a.673.673,0,0,0-.67.675.673.673,0,0,0,.67.675l20.808-.005-3.157,3.163a.683.683,0,0,0,.005.956.672.672,0,0,0,.95-.005l4.279-4.31h0a.758.758,0,0,0,.14-.213.644.644,0,0,0,.052-.26.677.677,0,0,0-.192-.473l-4.279-4.31A.662.662,0,0,0,14.3,11.443Z"
                transform="translate(4.035 -11.252)"
                fill="#fff"
              />
            </svg>
          </span>

          <Dialog.Title
            as="h3"
            className={`${style.wallet_dialog_title} flex flex-col`}
          >
            <div className={`${style2.title}`}>
              <p className={style2.title_p}>
                <img src={lockWhite} alt="lock" />
                Create new lock
              </p>
            </div>
            <span className={`${style2.sub} ${style2.title_p_sub}`}>
              Select the type of token you would like to create a lock for. You
              can create multiple locks with different settings for each one
            </span>
          </Dialog.Title>

          <span
            onClick={handleOnCloseModal}
            className={style.wallet_dialog_close}
            style={{ alignSelf: "flex-start" }}
          >
            &times;
          </span>
        </div>

        <div className={style.wallet_dialog_providers}>
          <ul className={style2.wallet_dialog_network_list}>
            {lockTypes.map(({ displayName, summary, icon, key }) => (
              <li key={key}>
                <button onClick={() => setLockType(key)}>
                  <img
                    src={icon}
                    alt={displayName}
                    className={`${style2.type_img_width}`}
                  />
                  <span
                    className={`${style2.wallet_dialog_display_name} flex flex-col text-left`}
                  >
                    <span>{displayName}</span>
                    <span className=" text-xs">{summary}</span>
                  </span>
                  <span
                    className={`${style2.wallet_dialog_chainStatus} ${
                      key === lockType ? style2.wallet_dialog_activeChain : ""
                    }`}
                  ></span>
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div className={style2.continue_button}>
          <Button
            disabled={!lockType}
            className={`${style2.continue_button_button}`}
            handleClick={() => setState(nextState(state))}
          >
            <p> Continue</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23.747"
              height="9.971"
              viewBox="0 0 23.747 9.971"
            >
              <path
                d="M14.3,11.443a.679.679,0,0,0-.005.956l3.157,3.163-20.813.005a.673.673,0,0,0-.67.675.673.673,0,0,0,.67.675l20.808-.005-3.157,3.163a.683.683,0,0,0,.005.956.672.672,0,0,0,.95-.005l4.279-4.31h0a.758.758,0,0,0,.14-.213.644.644,0,0,0,.052-.26.677.677,0,0,0-.192-.473l-4.279-4.31A.662.662,0,0,0,14.3,11.443Z"
                transform="translate(4.035 -11.252)"
              />
            </svg>
          </Button>
        </div>
      </div>
    );
  }

  function enterContractAddress() {
    return (
      <div className={style.wallet_dialog_content}>
        <div className={style.wallet_dialog_header}>
          <span
            onClick={() => setState(prevState(state))}
            className={`${style.wallet_dialog_close} ${style2.back_button}`}
            style={{ alignSelf: "flex-start" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 23.747 9.971"
              style={{ transform: "rotateY(180deg)" }}
              width="28px"
              height="28px"
            >
              <path
                d="M14.3,11.443a.679.679,0,0,0-.005.956l3.157,3.163-20.813.005a.673.673,0,0,0-.67.675.673.673,0,0,0,.67.675l20.808-.005-3.157,3.163a.683.683,0,0,0,.005.956.672.672,0,0,0,.95-.005l4.279-4.31h0a.758.758,0,0,0,.14-.213.644.644,0,0,0,.052-.26.677.677,0,0,0-.192-.473l-4.279-4.31A.662.662,0,0,0,14.3,11.443Z"
                transform="translate(4.035 -11.252)"
                fill="#fff"
              />
            </svg>
          </span>

          <Dialog.Title
            as="h3"
            className={`${style.wallet_dialog_title} flex flex-col`}
          >
            <div className={`${style2.title}`}>
              <p className={style2.title_p}>
                <img src={lockWhite} alt="lock" />
                Create new lock
              </p>
            </div>
            <span className={`${style2.sub} ${style2.title_p_sub}`}>
              Enter the pair address you would like to lock liquidity for
            </span>
          </Dialog.Title>

          <span
            onClick={handleOnCloseModal}
            className={style.wallet_dialog_close}
            style={{ alignSelf: "flex-start" }}
          >
            &times;
          </span>
        </div>

        <div className={style.wallet_dialog_providers}>
          <span className={style2.token_validity}>
            {tokenDetails?.symbol && (
              <>
                <img
                  src={tokenDetails?.logo || empty}
                  alt=""
                  className="lock-icons"
                />
                <p className={style2.configure_lock_symbol}>
                  {tokenDetails?.symbol}
                </p>
              </>
            )}
          </span>
          <SearchGroup
            isSearch={true}
            example={"0x00000000000000000000000000000000"}
            value={tokenAddress}
            handleChange={(e) => handleInputChange(e)}
            isLoading={isLoadingToken}
          />
        </div>

        <div className={style2.continue_button}>
          <Button
            disabled={!isValidAddress || !isValidAsset || !lockAddress}
            className={style2.continue_button_button}
            handleClick={() => setState(nextState(state))}
          >
            {isValidAsset && isValidAddress && <p>Continue</p>}
            {!isValidAddress && <p>Invalid Address</p>}
            {isValidAddress && !isValidAsset && <p>Invalid Token</p>}

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23.747"
              height="9.971"
              viewBox="0 0 23.747 9.971"
            >
              <path
                d="M14.3,11.443a.679.679,0,0,0-.005.956l3.157,3.163-20.813.005a.673.673,0,0,0-.67.675.673.673,0,0,0,.67.675l20.808-.005-3.157,3.163a.683.683,0,0,0,.005.956.672.672,0,0,0,.95-.005l4.279-4.31h0a.758.758,0,0,0,.14-.213.644.644,0,0,0,.052-.26.677.677,0,0,0-.192-.473l-4.279-4.31A.662.662,0,0,0,14.3,11.443Z"
                transform="translate(4.035 -11.252)"
              />
            </svg>
          </Button>
        </div>
      </div>
    );
  }

  function configureLock() {
    return (
      <div className={style.wallet_dialog_content}>
        <div className={style.wallet_dialog_header}>
          <span
            onClick={() => setState(prevState(state))}
            className={`${style.wallet_dialog_close} ${style2.back_button}`}
            style={{ alignSelf: "flex-start" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 23.747 9.971"
              style={{ transform: "rotateY(180deg)" }}
              width="28px"
              height="28px"
            >
              <path
                d="M14.3,11.443a.679.679,0,0,0-.005.956l3.157,3.163-20.813.005a.673.673,0,0,0-.67.675.673.673,0,0,0,.67.675l20.808-.005-3.157,3.163a.683.683,0,0,0,.005.956.672.672,0,0,0,.95-.005l4.279-4.31h0a.758.758,0,0,0,.14-.213.644.644,0,0,0,.052-.26.677.677,0,0,0-.192-.473l-4.279-4.31A.662.662,0,0,0,14.3,11.443Z"
                transform="translate(4.035 -11.252)"
                fill="#fff"
              />
            </svg>
          </span>

          <Dialog.Title
            as="h3"
            className={`${style.wallet_dialog_title} flex flex-col`}
          >
            <div className={`${style2.title}`}>
              <p className={style2.title_p}>
                <img src={lockWhite} alt="lock" />
                Configure lock
              </p>
            </div>
            <span className={`${style2.sub} ${style2.title_p_sub}`}></span>
          </Dialog.Title>

          <span
            onClick={handleOnCloseModal}
            className={style.wallet_dialog_close}
            style={{ alignSelf: "flex-start" }}
          >
            &times;
          </span>
        </div>

        <div className={style.wallet_dialog_providers}>
          <div className={style2.configure_lock_container}>
            <div className={style2.configure_lock_section}>
              <div className={style2.configure_lock_left}>
                <h3 className={style2.configure_lock_title}>Lock Amount</h3>
                <SearchGroup
                  placeholder={100}
                  className={style2.configure_lock_input}
                  value={lockAmount}
                  handleChange={(e) =>
                    onInputNumberChange(e, handleLockAmountChange)
                  }
                />
              </div>
              <div className={style2.configure_lock_right}>
                <h3 className={style2.configure_lock_balance}>
                  Balance: {genFormatter.format(balance)}
                </h3>
                <div className={style2.configure_lock_icons}>
                  <button onClick={() => setLockAmount(balanceFull)}>
                    MAX
                  </button>
                  <img src={baseline} alt="" className="lock-icons" />
                  <img
                    src={tokenDetails?.logo || empty}
                    alt=""
                    className="lock-icons"
                  />
                  <p className={style2.configure_lock_symbol}>
                    {tokenDetails?.symbol || "Null"}
                  </p>
                </div>
              </div>
            </div>

            <div className={style2.configure_lock_section}>
              <div className={style2.configure_lock_left}>
                <h3 className={style2.configure_lock_title}>Unlock Date</h3>
                <SearchGroup
                  placeholder={1}
                  value={lockDuration}
                  handleChange={(e) => {
                    onInputNumberChange(e, handleDayChange);
                  }}
                  className={style2.configure_lock_input}
                />
              </div>
              <div className={style2.configure_lock_right}>
                <h3 className={style2.configure_lock_balance}>
                  {numberOfDaysToDateString(lockDuration)}
                </h3>
                <div className={style2.configure_lock_icons}>
                  <p className={style2.configure_lock_symbol}>Days</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={style2.continue_button}>
          <Button
            disabled={
              lockAmount < 1 || lockDuration < 1 || balance < lockAmount
            }
            className={style2.continue_button_button}
            handleClick={() => setState(nextState(state))}
          >
            {lockAmount > 0 && lockDuration > 0 && lockAmount <= balance && (
              <p>Continue</p>
            )}
            {lockAmount < 1 && <p>Invalid Amount</p>}
            {lockAmount > 0 && lockDuration < 1 && <p>Invalid Duration</p>}
            {lockAmount > balance && lockDuration > 0 && (
              <p>Insufficient balance</p>
            )}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23.747"
              height="9.971"
              viewBox="0 0 23.747 9.971"
            >
              <path
                d="M14.3,11.443a.679.679,0,0,0-.005.956l3.157,3.163-20.813.005a.673.673,0,0,0-.67.675.673.673,0,0,0,.67.675l20.808-.005-3.157,3.163a.683.683,0,0,0,.005.956.672.672,0,0,0,.95-.005l4.279-4.31h0a.758.758,0,0,0,.14-.213.644.644,0,0,0,.052-.26.677.677,0,0,0-.192-.473l-4.279-4.31A.662.662,0,0,0,14.3,11.443Z"
                transform="translate(4.035 -11.252)"
              />
            </svg>
          </Button>
        </div>
      </div>
    );
  }

  function displaySummary() {
    return (
      <div className={style.wallet_dialog_content}>
        <div className={style.wallet_dialog_header}>
          <span
            onClick={() => setState(prevState(state))}
            className={`${style.wallet_dialog_close} ${style2.back_button}`}
            style={{ alignSelf: "flex-start" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 23.747 9.971"
              style={{ transform: "rotateY(180deg)" }}
              width="28px"
              height="28px"
            >
              <path
                d="M14.3,11.443a.679.679,0,0,0-.005.956l3.157,3.163-20.813.005a.673.673,0,0,0-.67.675.673.673,0,0,0,.67.675l20.808-.005-3.157,3.163a.683.683,0,0,0,.005.956.672.672,0,0,0,.95-.005l4.279-4.31h0a.758.758,0,0,0,.14-.213.644.644,0,0,0,.052-.26.677.677,0,0,0-.192-.473l-4.279-4.31A.662.662,0,0,0,14.3,11.443Z"
                transform="translate(4.035 -11.252)"
                fill="#fff"
              />
            </svg>
          </span>

          <Dialog.Title
            as="h3"
            className={`${style.wallet_dialog_title} flex flex-col`}
          >
            <div className={`${style2.title}`}>
              <p className={style2.title_p}>
                <img src={lockWhite} alt="lock" />
                Confirm lock
              </p>
            </div>
            <span className={`${style2.sub} ${style2.title_p_sub}`}></span>
          </Dialog.Title>

          <span
            onClick={handleOnCloseModal}
            className={style.wallet_dialog_close}
            style={{ alignSelf: "flex-start" }}
          >
            &times;
          </span>
        </div>

        <div className={style.wallet_dialog_providers}>
          <div className={style2.configure_lock_container}>
            <div className={style2.configure_lock_section}>
              <div className={style2.configure_lock_left}>
                <h3 className={style2.configure_lock_title}>
                  Selected Network
                </h3>
                <div className={style2.configure_lock_form_icons}>
                  <img
                    src={
                      findObjectByKey(supportedNetworks, selectedLockChain)
                        ?.icon
                    }
                    alt=""
                    className="lock-icons"
                  />
                  <SearchGroup
                    disabled
                    className={`${style2.configure_lock_input}  ${style2.configure_lock_input_disabled}`}
                    value={
                      findObjectByKey(supportedNetworks, selectedLockChain)
                        ?.displayName
                    }
                  />
                </div>
              </div>
              <div className={style2.configure_lock_right}>
                <h3 className={style2.configure_lock_title}>Token Type</h3>
                <div className={style2.configure_lock_form_icons}>
                  <img
                    src={findObjectByKey(lockTypes, lockType)?.icon}
                    alt=""
                    className="lock-icons"
                  />
                  <SearchGroup
                    disabled
                    className={`${style2.configure_lock_input}  ${style2.configure_lock_input_disabled}`}
                    value={findObjectByKey(lockTypes, lockType)?.displayName}
                  />
                </div>
              </div>
            </div>
            <div className={style2.configure_lock_section}>
              <div className={style2.configure_lock_addy}>
                <h3 className={style2.configure_lock_title}>
                  Address
                  <span>
                    <img
                      src={tokenDetails?.logo || empty}
                      alt=""
                      className="lock-icons"
                    />
                    {tokenDetails?.symbol}
                  </span>
                </h3>
                <div className={style2.configure_lock_form_icons}>
                  <img
                    src={tokenDetails?.logo || empty}
                    alt=""
                    className="lock-icons"
                  />
                  <SearchGroup
                    disabled
                    className={`${style2.configure_lock_input}  ${style2.configure_lock_input_disabled}`}
                    value={lockAddress}
                  />
                </div>
              </div>
            </div>
            <div className={style2.configure_lock_section}>
              <div className={style2.configure_lock_left}>
                <h3 className={style2.configure_lock_title}>Lock Amount</h3>
                <div className={style2.configure_lock_form_icons}>
                  <img src={lockYellow} alt="" className="lock-icons" />
                  <SearchGroup
                    disabled
                    value={genFormatter.format(lockAmount)}
                    className={`${style2.configure_lock_input}  ${style2.configure_lock_input_disabled}`}
                  />
                </div>
              </div>
              <div className={style2.configure_lock_left}>
                <h3 className={style2.configure_lock_title}>Unlock Date</h3>
                <div className={style2.configure_lock_form_icons}>
                  <img src={lockdate} alt="" className="lock-icons" />
                  <SearchGroup
                    disabled
                    value={numberOfDaysToDateString(lockDuration)}
                    className={`${style2.configure_lock_input}  ${style2.configure_lock_input_disabled}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`${style2.continue_button} ${style2.continue_approve_confirm}`}
        >
          <Button
            disabled={approved}
            isLoading={isApproving}
            type={isApproving ? "outlined" : "normal"}
            handleClick={approveSpending}
            className={`${style2.continue_button_button} ${style2.approve_confirm}`}
          >
            {approved ? <p>Approved</p> : <p>Approve Locker</p>}
          </Button>
          <Button
            type={isLocking ? "outlined" : "normal"}
            isLoading={isLocking}
            handleClick={lockTheTokens}
            disabled={!approved}
            className={`${style2.continue_button_button} ${style2.approve_confirm}`}
          >
            <p> Confirm Lock</p>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className={style.wallet_dialog}
          onClose={handleOnCloseModal}
        >
          <div className={style.wallet_dailog_containter}>
            <Dialog.Overlay className={style.wallet_dialog_overlay} />

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className={style.wallet_dialog_containter_spacer}
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {
                {
                  chain: selectChain(),
                  type: selectLockType(),
                  address: enterContractAddress(),
                  config: configureLock(),
                  summary: displaySummary(),
                }[states[state]]
              }
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default CreateLock;

function nextState(current) {
  return current + 1;
}

function prevState(current) {
  return current - 1;
}
