import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { MoralisProvider } from "react-moralis";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

import * as serviceWorker from "./serviceWorker";
import { DingerDappProvider } from "./providers/DingerDappProvider/MoralisDappProvider";

const appId = process.env.REACT_APP_MORALIS_APP_ID;
const serverUrl = process.env.REACT_APP_MORALIS_SERVER_URL;

const options = {
  timeout: 4000,
  position: positions.TOP_RIGHT,
};

ReactDOM.render(
  <React.StrictMode>
    <MoralisProvider appId={appId} serverUrl={serverUrl}>
      <DingerDappProvider>
        <Provider template={AlertTemplate} {...options}>
          <App />
        </Provider>
      </DingerDappProvider>
    </MoralisProvider>
  </React.StrictMode>,

  document.getElementById("root")
);

serviceWorker.unregister();
