import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import style from "./lock-details.module.css";
import copy from "../../assets/images/copy-blue.svg";
import locked from "../../assets/images/locked.svg";
import shortedAddress from "../../utils/shortenAddress";
import CopyToClipboard from "react-copy-to-clipboard";
import Spacer from "../../components/common/spacer";
import {
  chainIdToUse,
  chainToUse,
  farmToUse,
  genFormatter,
  getUsdFormat,
  linksToUse,
  timestampToDuration,
} from "../../utils/utils";

import { useDingerDapp } from "../../providers/DingerDappProvider/MoralisDappProvider";
import { useMoralis } from "react-moralis";
import { defaultLockDetailsState } from "../../utils/defaultLockDetailsState";
import {
  getLocksAddressAndId,
  getLockType,
  getMetaData,
  structuredLockData,
} from "../../utils/lockDetails";
import loading from "../../assets/images/loading.svg";
import { ManageLockButton } from "../../components/dlock/manage-lock-button";
import { useAlert } from "react-alert";

function DlockDetailsForOne() {
  const alert = useAlert();
  const {
    chainId,
    walletAddress,
    locker: lockerContract,
    setChainId,
    setLocker,
  } = useDingerDapp();
  const navigate = useNavigate();
  const { enableWeb3, isAuthenticated, Moralis } = useMoralis();
  const { chain, address, id } = useParams();
  const TITLE = "D-Lock | Lock Details";
  const [copied, setCopied] = useState(false);
  const [lockDetails, setLockDetails] = useState(defaultLockDetailsState);
  const [isLoading, setIsLoading] = useState(true);
  const [isOwner, setIsOwner] = useState(false);

  const links = linksToUse(chainId, address);

  const addressCopied = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const runSetChain = useCallback(() => {
    setChainId(chainIdToUse(chain ?? "erc"));
    setLocker(farmToUse(chainIdToUse(chain)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chain, lockerContract]);

  const runGetLockDetails = useCallback(async () => {
    if (isAuthenticated) {
      enableWeb3();
      setIsLoading(true);
      if (lockerContract) {
        const lockInfo = await getLockByTokenAddressAndId(address, id);
        setLockDetails(lockInfo);
        if (lockInfo?.total_token_supply) setIsLoading(false);
        // if (lockInfo.hasError) setHasError(true);
      }
    } else {
      navigate(`/dlock/${chainToUse(chainId)}/locks/${address}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address && lockerContract, id, isAuthenticated]);

  const runCheckOwnership = useCallback(() => {
    if (isAuthenticated && lockDetails?.lock_type) {
      const owner = lockDetails?.locks
        ? lockDetails?.locks[0]?.owner?.toLowerCase()
        : "";
      console.log("Owner", owner);
      setIsOwner(owner === walletAddress.toLowerCase());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress, lockDetails?.locks, isAuthenticated]);

  useEffect(() => {
    runSetChain();
  }, [runSetChain]);

  useEffect(() => {
    runCheckOwnership();
  }, [runCheckOwnership]);

  useEffect(() => {
    runGetLockDetails();
  }, [runGetLockDetails]);

  async function getLockByTokenAddressAndId(address, id) {
    let structuredLockDetails = {};

    try {
      const locks = await getLocksAddressAndId(
        Moralis,
        chainId,
        lockerContract,
        address,
        id
      );
      const token = (await getMetaData(Moralis, chainId, address))[0];
      const lockTp = await getLockType(Moralis, chainId, address);

      if (lockTp === "Liquidity") {
        structuredLockDetails = await structuredLockData(
          Moralis,
          chainId,
          locks,
          token,
          address,
          true
        );
      } else {
        // get token details
        structuredLockDetails = await structuredLockData(
          Moralis,
          chainId,
          locks,
          token,
          address,
          false
        );
      }
    } catch ({ error }) {
      alert.error(error);
    }

    return structuredLockDetails;
  }

  return (
    <div className={style.lock_details_container}>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Spacer my={1.5} className={`hidden`} />
      <div className={style.lock_details_backdrop}>
        <div className={style.lock_details}>
          {lockDetails?.total_locked_tokens > 0 ? (
            <>
              <div className={style.lock_details_header}>
                {lockDetails?.lock_type === "Liquidity" ? (
                  <>
                    <div className={style.lock_details_symbols}>
                      <div className={style.lock_details_symbol}>
                        <img
                          src={lockDetails?.token_1_icon}
                          alt={lockDetails?.token_1_symbol || ""}
                        />
                        <img
                          src={lockDetails?.token_0_icon}
                          alt={lockDetails?.token_0_symbol || ""}
                        />
                      </div>
                      <h1 className={style.lock_details_title}>
                        {lockDetails?.token_1_symbol}/
                        {lockDetails?.token_0_symbol}
                      </h1>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={style.lock_details_symbols}>
                      <div className={style.lock_details_symbol}>
                        <img
                          src={lockDetails?.token_1_icon}
                          alt={lockDetails?.token_1_symbol || ""}
                        />
                      </div>
                      <h1 className={style.lock_details_title}>
                        {lockDetails?.token_1_symbol}
                      </h1>
                    </div>
                  </>
                )}

                <div className={style.lock_details_links}>
                  {links.map(({ name, icon, link }, key) => (
                    <a
                      href={link}
                      key={key}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p className={style.lock_details_link}>
                        <img src={icon} alt={name} />
                        <span>{name}</span>
                      </p>
                    </a>
                  ))}
                  <CopyToClipboard
                    text={address}
                    onCopy={() => addressCopied()}
                  >
                    <p className={style.lock_details_link}>
                      <img src={copy} alt="copy" />
                      <span>{shortedAddress(address, 2, 3)}</span>
                      {copied ? (
                        <span className={style.tooltip}>Copied.</span>
                      ) : null}
                    </p>
                  </CopyToClipboard>
                </div>
              </div>
              <div className={style.lock_details_content}>
                <div className={style.lock_details_content_values}>
                  <div className={`${style.lock_details_content_body}`}>
                    <div className={style.lock_value}>
                      <p className={style.lock_value_title}>Value</p>
                      <p className={style.lock_value_amount}>
                        {getUsdFormat(lockDetails?.locked_value || 0)}
                      </p>
                      <p className={style.lock_value_token}>
                        {genFormatter.format(
                          lockDetails?.total_locked_tokens || 0
                        )}
                        {` ${lockDetails?.pair_symbol || "Token"}`}
                      </p>
                    </div>

                    <div
                      className={`${style.lock_locked} ${style.desktop_lock_locked}`}
                    >
                      <img src={locked} alt="locked" />
                      <p className={style.lock_locked_percentage}>
                        <span>{lockDetails?.percentage_locked || 0}%</span>
                        <span>LOCKED</span>
                      </p>
                      <p className={style.lock_locked_values}>
                        <span className={style.lock_locked_values_l}>
                          {getUsdFormat(lockDetails?.locked_value || 0)}
                        </span>
                        <span className={style.lock_locked_values_t}>
                          {" "}
                          / {genFormatter.format(lockDetails?.total_value || 0)}
                        </span>
                      </p>
                    </div>

                    <div className={style.lock_unlock}>
                      <p className={style.lock_value_title}>Unlock Date</p>
                      <p className={style.lock_value_amount}>
                        In {timestampToDuration(lockDetails?.unlock_date || 0)}
                      </p>
                      <p className={style.lock_value_token}>
                        {new Date(lockDetails?.unlock_date).toLocaleString()}
                      </p>
                    </div>
                  </div>
                  <div className={`${style.lock_locked} mobile-locked`}>
                    <img src={locked} alt="locked" />
                    <p className={style.lock_locked_percentage}>
                      <span>{lockDetails?.percentage_locked || 0}%</span>
                      <span>LOCKED</span>
                    </p>
                    <p className={style.lock_locked_values}>
                      <span className={style.lock_locked_values_l}>
                        {getUsdFormat(lockDetails?.locked_value || 0)}
                      </span>
                      <span className={style.lock_locked_values_t}>
                        {" "}
                        / {genFormatter.format(lockDetails?.total_value || 0)}
                      </span>
                    </p>
                  </div>
                  <div className={style.lock_details_footer}>
                    <p>
                      Total Supply:{" "}
                      {genFormatter.format(
                        lockDetails?.total_token_supply || 0
                      )}
                    </p>
                    <p>
                      Total Locked:{" "}
                      {genFormatter.format(
                        lockDetails?.total_locked_tokens || 0
                      )}
                    </p>
                  </div>
                </div>
                <ManageLockButton
                  isOwner={isOwner}
                  lockId={id}
                  unlockDate={lockDetails?.unlock_date}
                  amount={
                    lockDetails?.locks
                      ? lockDetails?.locks[0]?.originalAmount
                      : 0
                  }
                />
              </div>
            </>
          ) : (
            <div className={style.lock_details_loading}>
              <h2>Lock id:{id} cannot be found for:</h2>
              <Spacer my={1} />
              <p>{address}</p>
              <p>It is either withdrawn or was never locked!</p>
            </div>
          )}

          {isLoading && (
            <div className={style.lock_details_loading}>
              <img src={loading} alt="loading" width="60px" />
              <h3>Fetching latest data...</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DlockDetailsForOne;
