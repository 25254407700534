import React from "react";
import shortedAddress from "../../utils/shortenAddress";
import style from "./lock-table.module.css";
import { Link } from "react-router-dom";
import { chainToUse } from "../../utils/utils";
import { useDingerDapp } from "../../providers/DingerDappProvider/MoralisDappProvider";

function UserLocksTable({ locks }) {
  const { chainId } = useDingerDapp();
  return (
    <div>
      <div className="w-full">
        <table className={`${style.lTable} t-desktop`}>
          <thead className={`${style.lTableHead}`}>
            <tr>
              <th>Token</th>
              <th>Symbol</th>
              <th>Type</th>
              <th className={`${style.linkCol}`}>Manage</th>
            </tr>
          </thead>
          <tbody className={`${style.lBody}`}>
            {locks?.map((lock, i) => (
              <tr className={style.tRow} key={i}>
                <td className={style.token}>
                  <div>
                    <p>{shortedAddress(lock?._address, 5, 4)}</p>
                  </div>
                </td>
                <td className={style.symbol}>
                  <div>
                    {lock?._symbol}
                    {lock?._is_lp === "Liquidity" && (
                      <span>
                        {lock?._token_0_symbol}/{lock?._token_1_symbol}
                      </span>
                    )}
                  </div>
                </td>
                <td className={style.lockDate}>
                  <div>{lock?._is_lp}</div>
                </td>
                <td
                  className={`${style.lockStatus} ${style.lockStatus_manage}`}
                >
                  <Link
                    to={`/dlock/${chainToUse(chainId)}/locks/${lock?._address}`}
                  >
                    <div className={style.lockStatus_locked}>
                      <p>
                        <span>Open</span>
                      </p>
                    </div>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="t-mobile">
          {locks?.map((lock, index) => (
            <div key={index} className="row">
              <h4 className="column-head">Token</h4>
              <p className="column-data">
                {shortedAddress(lock?._address, 5, 4)}
              </p>
              <h4 className="column-head">Symbol</h4>
              <p className="column-data">
                {" "}
                {lock?._token_0_symbol}/{lock?._token_1_symbol}
              </p>
              <h4 className="column-head">Type</h4>
              <p className="column-data">{lock?._is_lp}</p>
              <h4 className="column-head">Manage</h4>
              <div className="column-data">
                <Link
                  to={`/dlock/${chainToUse(chainId)}/locks/${lock?._address}`}
                >
                  <div className={style.lockStatus_locked}>
                    <p>
                      <span>Open</span>
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default UserLocksTable;
