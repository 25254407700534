import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Spacer from "../../components/common/spacer";
import LockTable from "../../components/dlock/lock-table";
import MyLocks from "../../components/dlock/mylocks";
import SearchLocks from "../../components/dlock/search-lock";
import TopStats from "../../components/dlock/top-stats";
import { useDingerDapp } from "../../providers/DingerDappProvider/MoralisDappProvider";
import { ListOfLocksProvider } from "../../providers/listOfLocks/listOfLocksProvider";
import { chainIdToUse, farmToUse } from "../../utils/utils.js";
// import { useMoralis } from "react-moralis";

function Dlock() {
  const TITLE = "D-Lock | Liquidity locker";
  const { chain } = useParams();
  const { setChainId, setLocker } = useDingerDapp();

  const runSetChain = useCallback(() => {
    setChainId(chainIdToUse(chain ?? "erc"));
    setLocker(farmToUse(chainIdToUse(chain)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chain]);

  useEffect(() => {
    runSetChain();
  }, [runSetChain]);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div>
        <ListOfLocksProvider>
          <TopStats />
          <Spacer my={3} />
          <MyLocks />
          <Spacer my={6} />
          <SearchLocks />
          <Spacer my={1.5} />
          <LockTable />
        </ListOfLocksProvider>
      </div>
    </>
  );
}

export default Dlock;
