import React from "react";
import shortedAddress from "../../utils/shortenAddress";
import {
  chainToUse,
  genFormatter,
  timestampToDuration,
} from "../../utils/utils";
import style from "./lock-table.module.css";
import { Link } from "react-router-dom";
import { useMoralis } from "react-moralis";
import { useDingerDapp } from "../../providers/DingerDappProvider/MoralisDappProvider";

function SubLocks({ locks }) {
  const { isAuthenticated } = useMoralis();
  const { chainId } = useDingerDapp();
  return (
    <div>
      <div className="w-full">
        <table className={`${style.lTable}`}>
          <thead className={`${style.lTableHead}`}>
            <tr>
              <th>Owner</th>
              <th>Locked On</th>
              <th>Amount</th>
              <th className={`${style.linkCol}`}>Unlocks In</th>
              <th className={`${style.linkCol}`}>Manage</th>
            </tr>
          </thead>
          <tbody className={`${style.lBody}`}>
            {locks?.map((lock, i) => (
              <tr className={style.tRow} key={lock?.lockID || i}>
                <td className={style.token}>
                  <div>
                    <p>{shortedAddress(lock?.owner, 5, 2)}</p>
                  </div>
                </td>
                <td className={style.lockDate}>
                  <div>{new Date(lock?.lockDate).toLocaleString()}</div>
                </td>
                <td className={style.amount}>
                  <div>
                    {genFormatter.format(lock?.amount || 0)}
                    <span>{lock?.percentage || 0}%</span>
                  </div>
                </td>

                <td className={style.unlocksIn}>
                  <div>
                    {timestampToDuration(lock?.unlockDate || 0)}
                    <span>{new Date(lock?.unlockDate).toLocaleString()}</span>
                  </div>
                </td>
                <td
                  className={`${style.lockStatus} ${style.lockStatus_manage}`}
                >
                  {isAuthenticated && lock?.amount > 0 ? (
                    <Link
                      to={`/dlock/${chainToUse(chainId)}/locks/${
                        lock?.address
                      }/${lock?.lockID}`}
                    >
                      <div className={style.lockStatus_locked}>
                        <p>
                          <span>Open</span>
                        </p>
                      </div>
                    </Link>
                  ) : (
                    <div className={style.lockStatus_locked}>
                      <p>
                        {lock?.amount <= 0 ? (
                          <span style={{ color: "red" }}>Withdrawn</span>
                        ) : (
                          <span>Connect Wallet</span>
                        )}
                      </p>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SubLocks;
