import React from "react";

function Spacer({ my, mx, className }) {
  return (
    <div
      className={`spacer ${className ?? ""}`}
      style={{ padding: `${my || 0}rem ${mx || 0}` }}
    />
  );
}

export default Spacer;
